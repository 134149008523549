import { Typography } from "@mui/material";
import type { SxProps } from "@mui/system";
import type { ReactNode } from "react";

export type PillProps = {
  variant?: "default" | "success" | "warning" | "error" | "info";
  children: ReactNode;
  sx?: SxProps;
};

export function Pill({ children, sx, variant = "default" }: PillProps) {
  return (
    <Typography
      variant="sh3"
      component="span"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        height: "20px",
        px: "6px",
        borderRadius: 1,
        textTransform: "uppercase",
        ...styleVariants[variant],
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
}

const styleVariants = {
  default: {
    color: "neutral.6",
    bgcolor: "neutral.2",
  },
  success: {
    color: "green.6",
    bgcolor: "green.2",
  },
  warning: {
    color: "yellow.6",
    bgcolor: "yellow.2",
  },
  error: {
    color: "red.6",
    bgcolor: "red.2",
  },
  info: {
    color: "blue.6",
    bgcolor: "blue.2",
  },
};
